exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-add-swisscows-email-in-apple-mail-js": () => import("./../../../src/pages/add-swisscows-email-in-apple-mail.js" /* webpackChunkName: "component---src-pages-add-swisscows-email-in-apple-mail-js" */),
  "component---src-pages-add-swisscows-email-in-outlook-js": () => import("./../../../src/pages/add-swisscows-email-in-outlook.js" /* webpackChunkName: "component---src-pages-add-swisscows-email-in-outlook-js" */),
  "component---src-pages-add-swisscows-email-to-android-outlook-js": () => import("./../../../src/pages/add-swisscows-email-to-android-outlook.js" /* webpackChunkName: "component---src-pages-add-swisscows-email-to-android-outlook-js" */),
  "component---src-pages-create-swisscows-email-account-js": () => import("./../../../src/pages/create-swisscows-email-account.js" /* webpackChunkName: "component---src-pages-create-swisscows-email-account-js" */),
  "component---src-pages-help-js": () => import("./../../../src/pages/help.js" /* webpackChunkName: "component---src-pages-help-js" */),
  "component---src-pages-imprint-js": () => import("./../../../src/pages/imprint.js" /* webpackChunkName: "component---src-pages-imprint-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-privacy-js": () => import("./../../../src/pages/privacy.js" /* webpackChunkName: "component---src-pages-privacy-js" */),
  "component---src-pages-question-10-js": () => import("./../../../src/pages/question10.js" /* webpackChunkName: "component---src-pages-question-10-js" */),
  "component---src-pages-question-11-js": () => import("./../../../src/pages/question11.js" /* webpackChunkName: "component---src-pages-question-11-js" */),
  "component---src-pages-question-12-js": () => import("./../../../src/pages/question12.js" /* webpackChunkName: "component---src-pages-question-12-js" */),
  "component---src-pages-question-13-js": () => import("./../../../src/pages/question13.js" /* webpackChunkName: "component---src-pages-question-13-js" */),
  "component---src-pages-question-14-js": () => import("./../../../src/pages/question14.js" /* webpackChunkName: "component---src-pages-question-14-js" */),
  "component---src-pages-question-15-js": () => import("./../../../src/pages/question15.js" /* webpackChunkName: "component---src-pages-question-15-js" */),
  "component---src-pages-question-5-js": () => import("./../../../src/pages/question5.js" /* webpackChunkName: "component---src-pages-question-5-js" */),
  "component---src-pages-question-6-js": () => import("./../../../src/pages/question6.js" /* webpackChunkName: "component---src-pages-question-6-js" */),
  "component---src-pages-question-7-js": () => import("./../../../src/pages/question7.js" /* webpackChunkName: "component---src-pages-question-7-js" */),
  "component---src-pages-question-8-js": () => import("./../../../src/pages/question8.js" /* webpackChunkName: "component---src-pages-question-8-js" */),
  "component---src-pages-question-9-js": () => import("./../../../src/pages/question9.js" /* webpackChunkName: "component---src-pages-question-9-js" */),
  "component---src-pages-quick-start-js": () => import("./../../../src/pages/quick-start.js" /* webpackChunkName: "component---src-pages-quick-start-js" */),
  "component---src-pages-terms-js": () => import("./../../../src/pages/terms.js" /* webpackChunkName: "component---src-pages-terms-js" */),
  "component---src-templates-help-page-js": () => import("./../../../src/templates/help-page.js" /* webpackChunkName: "component---src-templates-help-page-js" */)
}

