import React from "react"
import WrapRootElement from "./src/components/WrapRootElement";
import WrapPageElement from "./src/components/WrapPageElement";

export function wrapRootElement({ element }) {
    return <WrapRootElement>{element}</WrapRootElement>;
}

export function wrapPageElement({ element }) {
    return <WrapPageElement>{element}</WrapPageElement>;
}