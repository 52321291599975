import React, { useState, useMemo, useEffect } from "react";
import { PWAContext } from "../PWAContext";

export default function WrapRootElement({ children }) {
    const [installPrompt, setInstallPrompt] = useState(null);
    
    const value = useMemo(() => ({
        installPrompt,
        setInstallPrompt    
    }), [installPrompt]);

    useEffect(() => {
        window.addEventListener('beforeinstallprompt', (e) => {
            e.preventDefault();
            setInstallPrompt(e);
        });

        window.addEventListener('appinstalled', () => {
            setInstallPrompt(null);
        });
    }, []);


    return (
        <PWAContext.Provider value={value}>
            {children}
        </PWAContext.Provider>
    );
}