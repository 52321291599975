import React, { useEffect } from "react";
import { navigate } from "gatsby";
import { useLocation } from '@reach/router';
import { useLocalization } from "gatsby-theme-i18n";

export default function WrapPageElement({ children }) {
    const location = useLocation();
    const { defaultLang, prefixDefault, config } = useLocalization();

    useEffect(() => {
        if (!prefixDefault || config.some(locale => location.pathname.startsWith(`/${locale.code}/`)))
            return;

        let localePrefix = defaultLang;
        for (const browserLang of navigator.languages)
        {
            let supportedLang = config.find(locale => locale.hrefLang === browserLang || locale.code === browserLang);
            if (supportedLang) {
                localePrefix = supportedLang.code;
                break;
            }
        }

        navigate(`/${localePrefix}${location.pathname}`)        
    }, []);

    return <>{children}</>;
}